@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 7.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.75rem;
  width: 100%;
  @include tab() {
    margin-top: 5.125rem;
    grid-template-columns: repeat(1, 1fr);
  }

  .ctaBannerContent {
    display: flex;
    flex-direction: column;
    gap: 6.25rem;
    width: 100%;

    @include tab() {
      flex-direction: column;
      align-items: center;
    }
  }
  .ctaBannerTitle {
    display: flex;
    flex-direction: column;
    color: $primary;
    span {
      color: $dark;
    }
    @include tab() {
      text-align: center;
      align-items: center;
    }
  }
  .description {
    text-align: left;
    width: 100%;

    @include tab() {
      text-align: center;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
}
